<template>
	<v-sheet class="meeting-main-listing" style="height: calc(100vh - 88px)">
		<v-layout class="flex-wrap">
			<v-flex class="py-0" md="4">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					solo
					:style="{ width: '220px' }"
					:menu-props="{ offsetY: true }"
					v-on:change="statusFilterRows($event)"
				>
					<template #item="{ item }">
						<div class="d-flex align-center">
							<v-chip :color="item.status_color" :text-color="item.textcolor">
								<span class="fw-600 white--text">{{ item.status_count }}</span>
							</v-chip>
							<div class="ml-3 py-2 fw-500">{{ item.text }}</div>
							<!-- :class="[`${item.status_color}--text`]" -->
						</div>
						<!-- <v-list-item-action>
							<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
								<span class="white--text">{{ item.status_count }}</span>
							</v-avatar>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content> -->
					</template>
				</v-select>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end" md="4">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-flex class="d-flex justify-content-end mr-1">
						<div class="mr-2 fw-600 my-auto">Filter By</div>

						<!--<div class="filterTagSelect ml-3">
							 <v-autocomplete
								v-model="filter_label"
								:items="labelItems"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-circle"
								:color="filter_label == 'High' ? 'red' : filter_label == 'Medium' ? 'orange' : 'blue'"
								:class="
									filter_label == 'High'
										? 'red-icon'
										: filter_label == 'Medium'
										? 'orange-icon'
										: 'blue-icon'
								"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
							>
								<template v-slot:item="data">
									<template>
										<v-list-item-icon class="mr-0 my-1">
											<v-icon
												class="mdi-18px"
												:color="data.item == 'High' ? 'red' : data.item == 'Medium' ? 'orange' : 'blue'"
												>mdi-circle</v-icon
											>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title v-html="data.item"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div> -->

						<div class="d-flex filterTagSelect ml-2">
							<v-autocomplete
								:items="meetingTypeList"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-filter"
								outlined
								:style="{ width: '220px' }"
								clearable
								v-on:click:clear="clearFilter('meeting_type')"
								item-value="value"
								item-text="text"
								v-model="type_filter"
								hide-details
								class="filterSelect mr-2"
								placeholder="Meeting Type"
								v-on:change="topBarFilter"
							>
							</v-autocomplete>

							<v-autocomplete
								:items="users"
								clearable
								v-if="AllowAnyOforGetRoleNType(['master', 'admin', 'manager'])"
								v-model="user_filter"
								v-on:click:clear="(user_filter = null), getListing()"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								:style="{ width: '250px' }"
								v-on:change="topBarFilter"
								hide-details
								small-chips
								item-text="display_name"
								item-value="id"
								class="filterSelect"
								placeholder="Sales Person"
							>
								<template v-slot:selection="data">
									<v-chip small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left v-if="data.item.profile_img">
											<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>

										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div>
					</v-flex>
					<v-btn
						v-if="getPermission('meeting:create')"
						depressed
						tile
						color="red darken-4"
						class="text-white"
						@click="createMeeting"
						:disabled="pageLoading"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						:disabled="pageLoading"
						v-on:click="viewTypedata"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn>
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="routeSetting()"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<!-- 	<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<!-- <template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template> -->
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="referess">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('member')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('meeting')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<!-- summary-->
		<v-layout
			class="page-summary"
			:class="summary_show ? 'show' : ''"
			style="display: grid; grid-template-columns: repeat(5, 1fr)"
		>
			<template v-for="(statusData, key) in listingStatus">
				<PageSummeryBox
					:key="key"
					:color="statusData.status_color"
					:text="statusData.text"
					:count="statusData.status_count"
					@click="status != statusData.value && statusFilterRows($event, statusData.value)"
				/>
			</template>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Meeting #, Title, Location, Meeting Date, Meeting Added and Attendees"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>
					<ul>
						<li>
							Advance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-if="!viewType">
			<Table
				v-if="getPermission('meeting:view')"
				:page-loading="pageLoading"
				type="meeting"
				delete-endpoint="meeting/"
				detail-route="meeting-detail"
				is-drawer-detail
				v-on:open-drawer="open_drawer($event)"
				v-on:reload:content="filterRows"
				v-on:mailSend="sendMail($event)"
				:status-filter-data="statusFilterData"
			></Table>
		</template>
		<template v-else>
			<!-- <MeetingKanban v-on:mailSend="sendMail($event)"></MeetingKanban> -->
			<MeetingKanbanNew
				v-on:mailSend="sendMail($event)"
				v-on:scucess="getListing()"
				@loaded="pageLoading = false"
			></MeetingKanbanNew>
		</template>

		<ExportDialog
			v-if="exportCurrentDialog"
			endpoint="member/export?type=member&current=1"
			title="Members"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<exportDialog
			v-if="exportDialog"
			endpoint="member/export?type=member"
			title="Members"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></exportDialog>
		<ImportDialog
			v-if="importDialog"
			endpoint="member/import?type=member"
			title="Members"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			v-if="advanceSearch"
			title="Members"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			v-if="drawerFilter"
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="meeting"
			search-title="Meeting"
		></AdvanceFilter>

		<CreateMeeting
			v-if="create_meeting"
			:get-meeting-data="editMeeting"
			:attendies="attendieList"
			:drawer="create_meeting"
			title-status="Create"
			@close="create_meeting = false"
		></CreateMeeting>

		<EmailTemplate
			v-if="mail_dialog"
			:drawer="mail_dialog"
			:attendies="mailAttendies"
			:related-id="mailData.id"
			v-on:close="mail_dialog = false"
			v-on:scucess="getListing()"
			related="meeting"
		></EmailTemplate>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";

import { mapGetters } from "vuex";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import { GET, QUERY } from "@/core/services/store/request.module";
import { MeetingEventBus } from "@/core/lib/meeting.form.lib";
import { toSafeInteger } from "lodash";
import { map } from "lodash";

export default {
	name: "meeting-listing",
	title: "Listing Meeting",
	mixins: [ListingMixin],
	data() {
		return {
			skipBottomHeight: 75,
			viewType: false,
			drawerFilter: false,
			mailData: {},
			mailAttendies: {},
			filter_tag: "Software",
			labelItems: ["High", "Medium", "Low"],
			filter_label: "Medium",
			userItems: ["Everyone"],
			user_filter: null,
			type_filter: null,
			addvanceFilterAarry: [],
			create_meeting: false,
			users: [],
			pageTitle: "Meeting",
			pageBreadcrumbs: [{ text: "Meeting", disabled: true }],
			endpoint: "meeting",
			mail_dialog: false,
			defaultFilter: {},
			statusLists: [],
			statusFilterData: [],
			editMeeting: {},
			attendieList: {},
			status: "all",
			meetingTypeList: [
				{ value: "all", text: "All Meeting" },
				{ value: "bthrust", text: "Face to Face Bthrust" },
				{ value: "client", text: "Face to Face Client" },
				/* 	{ value: "zoom", text: "Zoom" }, */
				{ value: "other", text: "Zoom" },
			],
			summary_show: true,
		};
	},
	methods: {
		resetOtherFilters() {
			this.type_filter = null;
			this.user_filter = null;
		},
		clearFilter(type = "") {
			if (type == "meeting_type") {
				this.type_filter = null;
				this.getListing();
				//(type_filter = null), getListing()
			}
		},
		referess() {
			window.location.reload();
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		sendMail(data) {
			this.mailAttendies = map(data.attendies, (row) => {
				return row.email;
			});
			this.mailData = data;
			this.mail_dialog = true;
			/* alert(this.mail_dialog)	   */
		},
		open_drawer(id) {
			this.create_meeting = true;
			this.$store
				.dispatch(GET, { url: `single-meeting/${id}` })
				.then((data) => {
					this.editMeeting = data.meeting;

					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getCustomerStatus() {
			this.$store
				.dispatch(GET, { url: "meeting-setting" })
				.then((data) => {
					this.statusLists = data.status;
					this.users = data.users;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		createMeeting() {
			(this.editMeeting = {}), (this.attendieList = {});
			this.create_meeting = true;
		},
		topBarFilter() {
			this.pageLoading = true;
			this.allFiltersSetting();
			this.$store
				.dispatch(QUERY, {
					url: `meeting`,
					data: {
						status: this.status,
						meeting_type: this.type_filter ? this.type_filter : null,
						user_filter: this.user_filter ? this.user_filter : null,
						search: this.listingSearch || undefined,
					},
				})
				.then((data) => {
					this.$router.push({
						name: "meeting",
						query: {
							user_filter: this.user_filter,
							meeting_type: this.type_filter,
							t: new Date().getTime(),
							status: this.status,
							search: this.listingSearch || undefined,
						},
					});
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		/* viewTypedata() {
			this.viewType = !this.viewType;
			this.$router.push({
				name: "meeting",
				query: {
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		}, */
		allFiltersSetting() {
			this.$router.push({
				name: "meeting",
				query: {
					meeting_type: this.type_filter ? this.type_filter : null,
					user_filter: this.user_filter ? this.user_filter : null,
					search: this.listingSearch || undefined,
					t: new Date().getTime(),
				},
			});
		},
		statusFilterRows(event = "", statusText = "") {
			//let status
			if (statusText) {
				console.log(event);
				this.status = statusText;
			}
			if (statusText == "All Meeting") {
				this.status = "all";
			}

			this.allFiltersStatus();

			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `meeting`,
					data: {
						status: this.status,
						all: 1,
						user_filter: this.user_filter ? this.user_filter : null,
						meeting_type: this.type_filter ? this.type_filter : null,
						search: this.listingSearch || undefined,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus() {
			this.$router.push({
				name: "meeting",
				query: {
					status: this.status,
					t: new Date().getTime(),
					user_filter: this.user_filter ? this.user_filter : null,
					search: this.listingSearch || undefined,
				},
			});
		},
		/* routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "meeting",
				},
			});
		}, */
	},
	computed: {
		...mapGetters(["currentUser"]),
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		this.type_filter = this.$route.query.meeting_type;

		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
		/* 	this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
			meeting_type: this.type_filter ? this.type_filter : null,
		}; */
	},
	mounted() {
		this.getCustomerStatus();
		let filterQuery = this.$route.query;
		if (filterQuery.viewType != this.viewType.toString()) {
			this.$router.replace({
				name: "meeting",
				query: {
					...filterQuery,
					viewType: this.viewType,
					t: new Date().getTime(),
				},
			});
			this.viewType = this.$route.query.viewType == "true" ? true : false;
		}
		MeetingEventBus.$on("refresh", () => {
			this.getListing();
		});

		/* 	this.topBarFilter(); */
	},
	components: {
		//MeetingKanban,
		// MeetingKanbanNew,
		MeetingKanbanNew: () => import("@/view/module/meeting/MeetingKanbanNew"),
		CreateMeeting: () => import("@/view/pages/leads/create/CreateMeeting"),
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
		EmailTemplate: () => import("@/view/module/meeting/MailTemplateDrawar"),
		PageSummeryBox: () => import("@/view/components/PageSummeryBox"),
	},
};
</script>
